.footerSection{
    margin-top: 5rem;
    box-shadow: 0 0 8px 0;
    background-color: #051b35;
}



.footerName{
    color: #ffffff;
    margin-top: 1rem;
    /*font-family: 'Raleway',sans-serif;*/
    font-family: 'tajawal',sans-serif;
    font-size: 22px;
    font-weight: 600;
}



.social-container{
    margin-top: 30px;
}


a.social{
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
}
a.social:hover{
    transform: translateY(-4px);
}

a.iconcolor{
    color: #ffffff;
}


.footerDescription{
    color: #e9e8e8;
    margin-top: 1rem;
    /*font-family: 'Raleway',sans-serif;*/
    font-family: 'tajawal',sans-serif;
    font-size: 15px;
    font-weight: 400;

}

.footerLink{
    text-decoration: none;
    /*font-family: 'Raleway',sans-serif;*/
    font-family: 'tajawal',sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;

}
.footerLink:hover{
    text-decoration: none; 
    color: #ffd900;

}
.hrCopyRight
{
background: #ffffff none repeat scroll 0 0;
height: 1px;
width: 500px;
margin: -1.9rem auto 0;
margin-bottom: 1rem;
}