.projectCard{
    background: #ffffff;
    width: 22em;
    border-radius: 0.6em;
    margin: 1em;
    cursor: pointer;
    box-shadow: 0 13px 25px -5px;
    transition: all ease 200ms;
}

.projectCard:hover{
    
    box-shadow: 1px 2px 16px 0 rgb(224, 80, 80);
    transform: scale(1.07); 
    box-shadow: 0 13px 30px -5px;

}




.projectName{
    color: #051b35;
    margin-top: 1rem;
    /*font-family: 'Raleway',sans-serif;*/
    font-family: 'tajawal',sans-serif;
    font-size: 24px;
    font-weight: 700;
}
.projectDescription{
    color: #505050;
    margin-top: 1rem;
    /*font-family: 'Raleway',sans-serif;*/
    font-family: 'tajawal',sans-serif;
    font-size: 15px;
    font-weight: 400;

}