.courseImg{
    width: 150px;
    height: 150px;
}

.courseViewMore{
    color: #051b35;
    /*font-family: 'Montserrat',sans-serif;*/
    font-family: 'tajawal',sans-serif;
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
}
.courseViewMore:hover{
    text-decoration: none;
    color: #0152b4;
}