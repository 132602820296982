.topFixedBanner{
    background-image: url("../image/banner.jpg");
    min-height: 550px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.topBanneroverly{
    position: absolute;
    min-height: 550px;
    width: 100%;
    align-self: stretch;
    background: rgba(91, 132, 219, 0.3);    
    
}
.topContent{
    padding-top: 15em;
}
.topTitle{
    /*font-family: 'Montserrat',sans-serif;*/
    font-family: 'tajawal',sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 48px;
}
.topSubTitle{
    /*font-family: 'Raleway',sans-serif;*/
    font-family: 'tajawal',sans-serif;
    font-weight: 600;
    color: #ffffff;
    font-size: 22px;
}
Form
{
    font-family: 'tajawal',sans-serif;
    font-weight: 600;
    color: black;
    font-size: 22px;
}
.mt-5
{
    font-family: 'tajawal',sans-serif;
    font-weight: 400;
    color: black;
    font-size: 12px;
}

