.navTitle{
    /*font-family: 'Montserrat',sans-serif;*/
    font-family: 'tajawal',sans-serif;
    color: #ffffff !important;
    font-weight: 700;
    font-size: 30px;
}
.navTitleScroll{
    /*font-family: 'Montserrat',sans-serif;*/
    font-family: 'tajawal',sans-serif;
    color: #283653 !important;
    font-weight: 700;
    font-size: 30px;
}


.navBackground{
    -webkit-transition: background-color 400ms linear;
    -moz-transition: background-color 400ms linear;
    -o-transition: background-color 400ms linear;
    transition: background-color 400ms linear;
    background-color: rgba(228, 76, 76, 0)!important;
}
.navBackgroundScroll{
    -webkit-transition: background-color 400ms linear;
    -moz-transition: background-color 400ms linear;
    -o-transition: background-color 400ms linear;
    transition: background-color 400ms linear;
    background-color: #ffffff;
}


.navItem{
    text-decoration: none !important;
    /*font-family: 'Montserrat',sans-serif;*/
    font-family: 'tajawal',sans-serif;
    color: #ffffff ;
    font-weight: 700;
    font-size: 16px;
}
.navItemScroll{
    text-decoration: none !important;
    /*font-family: 'Montserrat',sans-serif;*/
    font-family: 'tajawal',sans-serif;
    color: #283653 !important;
    font-weight: 700;
    font-size: 16px;
}