.circleImg{
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border: 1px solid #051b35;
    border-radius: 50%;   


}

.Bgcolor{
    background-color: #051b35;
    min-height: 400px;
    border-radius: 1.5rem;


}

.Name{
    color: #ffffff;
    margin-top: 1rem;
    /*font-family: 'Raleway',sans-serif;*/
    font-family: 'tajawal',sans-serif;
    font-size: 24px;
    font-weight: 700;
}

.Description{
    color: #ffffff;
    margin-top: 1rem;
    /*font-family: 'Raleway',sans-serif;*/
    font-family: 'tajawal',sans-serif;
    font-size: 15px;
    font-weight: 400;

} 

.bottom1{
    background: #ffffff none repeat scroll 0 0;
    height: 3px;
    width: 100px;
    margin: -1.9rem auto 0;
    margin-bottom: 3rem;
}