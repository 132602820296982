.aboutImage{
    width: 400px;
    height: 400px;
    background-color: #81c0f3;
    border-radius: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.about1{
    color: #051b35;
    font-family: 'Raleway',sans-serif;
    font-weight: 600;
    font-size: 28px;
} 

.about2{
    color: #051b35;
    font-family: 'Montserrat',sans-serif;
    font-weight: 700;
    font-size: 40px;
} 

.aboutBody{
    width: 100%;
    height: 100%;
    padding-left: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10rem;
}